@use '~@angular/material' as mat;

@mixin anms-examples-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  anms-examples {
    .nav-responsive {
      border-bottom: 1px solid mat.get-color-from-palette($primary);

      mat-select {
        line-height: 48px;
        margin: 0 20px;
        width: 100%;
      }
    }
  }

  .nav-responsive-overlay {
    .active {
      color: mat.get-color-from-palette($accent, default-contrast);
      background-color: mat.get-color-from-palette($accent);
    }
  }
}
