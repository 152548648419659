@use '~@angular/material' as mat;

@mixin anms-stock-market-container-component-theme($theme) {
  $warn: map-get($theme, warn);

  anms-stock-market {
    mat-card {
      .negative {
        color: mat.get-color-from-palette($warn);
      }
    }

    .error-state {
      fa-icon {
        color: mat.get-color-from-palette($warn);
      }
    }
  }
}
