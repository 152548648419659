@use '~@angular/material' as mat;

@import '../../../../../styles-variables';

@mixin anms-notifications-component-theme($theme) {
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .error {
    color: mat.get-color-from-palette($warn, default-contrast);
    background-color: mat.get-color-from-palette($warn);
  }

  .information {
    color: mat.get-color-from-palette($accent, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .success {
    color: mat.get-color-from-palette($success-colors, default-contrast);
    background-color: mat.get-color-from-palette($success-colors);
  }

  .warning {
    color: mat.get-color-from-palette($warning-colors, default-contrast);
    background-color: mat.get-color-from-palette($warning-colors);
  }
}
