/*!
 * Bootstrap Reboot v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1320px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
*:active,
* :focus {
  outline: none !important;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

.mat-menu-content fa-icon {
  position: relative;
  top: 2px;
  left: 4px;
}

p a {
  border-bottom: 3px solid;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.default-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.default-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-option:hover:not(.mat-option-disabled), .default-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #283593;
}
.default-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #03a9f4;
}
.default-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d81b60;
}
.default-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.default-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.default-theme .mat-primary .mat-pseudo-checkbox-checked,
.default-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #283593;
}
.default-theme .mat-pseudo-checkbox-checked,
.default-theme .mat-pseudo-checkbox-indeterminate,
.default-theme .mat-accent .mat-pseudo-checkbox-checked,
.default-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #03a9f4;
}
.default-theme .mat-warn .mat-pseudo-checkbox-checked,
.default-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #d81b60;
}
.default-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.default-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.default-theme .mat-app-background, .default-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.default-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.default-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-badge {
  position: relative;
}
.default-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.default-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.default-theme .ng-animate-disabled .mat-badge-content,
.default-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.default-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.default-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.default-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.default-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.default-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .default-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.default-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .default-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.default-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .default-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.default-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .default-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.default-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.default-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.default-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.default-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .default-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.default-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .default-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.default-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .default-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.default-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .default-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.default-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.default-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.default-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.default-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .default-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.default-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .default-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.default-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .default-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.default-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .default-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.default-theme .mat-badge-content {
  color: white;
  background: #283593;
}
.cdk-high-contrast-active .default-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.default-theme .mat-badge-accent .mat-badge-content {
  background: #03a9f4;
  color: white;
}
.default-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #d81b60;
}
.default-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-button, .default-theme .mat-icon-button, .default-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.default-theme .mat-button.mat-primary, .default-theme .mat-icon-button.mat-primary, .default-theme .mat-stroked-button.mat-primary {
  color: #283593;
}
.default-theme .mat-button.mat-accent, .default-theme .mat-icon-button.mat-accent, .default-theme .mat-stroked-button.mat-accent {
  color: #03a9f4;
}
.default-theme .mat-button.mat-warn, .default-theme .mat-icon-button.mat-warn, .default-theme .mat-stroked-button.mat-warn {
  color: #d81b60;
}
.default-theme .mat-button.mat-primary.mat-button-disabled, .default-theme .mat-button.mat-accent.mat-button-disabled, .default-theme .mat-button.mat-warn.mat-button-disabled, .default-theme .mat-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-icon-button.mat-primary.mat-button-disabled, .default-theme .mat-icon-button.mat-accent.mat-button-disabled, .default-theme .mat-icon-button.mat-warn.mat-button-disabled, .default-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-stroked-button.mat-primary.mat-button-disabled, .default-theme .mat-stroked-button.mat-accent.mat-button-disabled, .default-theme .mat-stroked-button.mat-warn.mat-button-disabled, .default-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-button.mat-primary .mat-button-focus-overlay, .default-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .default-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #283593;
}
.default-theme .mat-button.mat-accent .mat-button-focus-overlay, .default-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .default-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #03a9f4;
}
.default-theme .mat-button.mat-warn .mat-button-focus-overlay, .default-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .default-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #d81b60;
}
.default-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .default-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .default-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.default-theme .mat-button .mat-ripple-element, .default-theme .mat-icon-button .mat-ripple-element, .default-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.default-theme .mat-button-focus-overlay {
  background: black;
}
.default-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-flat-button, .default-theme .mat-raised-button, .default-theme .mat-fab, .default-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.default-theme .mat-flat-button.mat-primary, .default-theme .mat-raised-button.mat-primary, .default-theme .mat-fab.mat-primary, .default-theme .mat-mini-fab.mat-primary {
  color: white;
}
.default-theme .mat-flat-button.mat-accent, .default-theme .mat-raised-button.mat-accent, .default-theme .mat-fab.mat-accent, .default-theme .mat-mini-fab.mat-accent {
  color: white;
}
.default-theme .mat-flat-button.mat-warn, .default-theme .mat-raised-button.mat-warn, .default-theme .mat-fab.mat-warn, .default-theme .mat-mini-fab.mat-warn {
  color: white;
}
.default-theme .mat-flat-button.mat-primary.mat-button-disabled, .default-theme .mat-flat-button.mat-accent.mat-button-disabled, .default-theme .mat-flat-button.mat-warn.mat-button-disabled, .default-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-raised-button.mat-primary.mat-button-disabled, .default-theme .mat-raised-button.mat-accent.mat-button-disabled, .default-theme .mat-raised-button.mat-warn.mat-button-disabled, .default-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-fab.mat-primary.mat-button-disabled, .default-theme .mat-fab.mat-accent.mat-button-disabled, .default-theme .mat-fab.mat-warn.mat-button-disabled, .default-theme .mat-fab.mat-button-disabled.mat-button-disabled, .default-theme .mat-mini-fab.mat-primary.mat-button-disabled, .default-theme .mat-mini-fab.mat-accent.mat-button-disabled, .default-theme .mat-mini-fab.mat-warn.mat-button-disabled, .default-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-flat-button.mat-primary, .default-theme .mat-raised-button.mat-primary, .default-theme .mat-fab.mat-primary, .default-theme .mat-mini-fab.mat-primary {
  background-color: #283593;
}
.default-theme .mat-flat-button.mat-accent, .default-theme .mat-raised-button.mat-accent, .default-theme .mat-fab.mat-accent, .default-theme .mat-mini-fab.mat-accent {
  background-color: #03a9f4;
}
.default-theme .mat-flat-button.mat-warn, .default-theme .mat-raised-button.mat-warn, .default-theme .mat-fab.mat-warn, .default-theme .mat-mini-fab.mat-warn {
  background-color: #d81b60;
}
.default-theme .mat-flat-button.mat-primary.mat-button-disabled, .default-theme .mat-flat-button.mat-accent.mat-button-disabled, .default-theme .mat-flat-button.mat-warn.mat-button-disabled, .default-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-raised-button.mat-primary.mat-button-disabled, .default-theme .mat-raised-button.mat-accent.mat-button-disabled, .default-theme .mat-raised-button.mat-warn.mat-button-disabled, .default-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .default-theme .mat-fab.mat-primary.mat-button-disabled, .default-theme .mat-fab.mat-accent.mat-button-disabled, .default-theme .mat-fab.mat-warn.mat-button-disabled, .default-theme .mat-fab.mat-button-disabled.mat-button-disabled, .default-theme .mat-mini-fab.mat-primary.mat-button-disabled, .default-theme .mat-mini-fab.mat-accent.mat-button-disabled, .default-theme .mat-mini-fab.mat-warn.mat-button-disabled, .default-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-flat-button.mat-primary .mat-ripple-element, .default-theme .mat-raised-button.mat-primary .mat-ripple-element, .default-theme .mat-fab.mat-primary .mat-ripple-element, .default-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-flat-button.mat-accent .mat-ripple-element, .default-theme .mat-raised-button.mat-accent .mat-ripple-element, .default-theme .mat-fab.mat-accent .mat-ripple-element, .default-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-flat-button.mat-warn .mat-ripple-element, .default-theme .mat-raised-button.mat-warn .mat-ripple-element, .default-theme .mat-fab.mat-warn .mat-ripple-element, .default-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-stroked-button:not([class*=mat-elevation-z]), .default-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-fab:not([class*=mat-elevation-z]), .default-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .default-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .default-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-button-toggle-standalone,
.default-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.default-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.default-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.default-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.default-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.default-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.default-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.default-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.default-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.default-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.default-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.default-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.default-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.default-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .default-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #283593;
}
.default-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .default-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #03a9f4;
}
.default-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .default-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #d81b60;
}
.default-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .default-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.default-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.default-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.default-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.default-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #283593;
}
.default-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.default-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #03a9f4;
}
.default-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.default-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #d81b60;
}
.default-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.default-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.default-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #283593;
  color: white;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #d81b60;
  color: white;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #03a9f4;
  color: white;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.default-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.default-theme .mat-table {
  background: white;
}
.default-theme .mat-table thead, .default-theme .mat-table tbody, .default-theme .mat-table tfoot,
.default-theme mat-header-row, .default-theme mat-row, .default-theme mat-footer-row,
.default-theme [mat-header-row], .default-theme [mat-row], .default-theme [mat-footer-row],
.default-theme .mat-table-sticky {
  background: inherit;
}
.default-theme mat-row, .default-theme mat-header-row, .default-theme mat-footer-row,
.default-theme th.mat-header-cell, .default-theme td.mat-cell, .default-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-cell, .default-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-datepicker-toggle,
.default-theme .mat-datepicker-content .mat-calendar-next-button,
.default-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-calendar-table-header,
.default-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-calendar-body-cell-content,
.default-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.default-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.default-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.default-theme .mat-calendar-body-in-range::before {
  background: rgba(40, 53, 147, 0.2);
}
.default-theme .mat-calendar-body-comparison-identical,
.default-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.default-theme .mat-calendar-body-comparison-bridge-start::before,
.default-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(40, 53, 147, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-calendar-body-comparison-bridge-end::before,
.default-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(40, 53, 147, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.default-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.default-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.default-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.default-theme .mat-calendar-body-selected {
  background-color: #283593;
  color: white;
}
.default-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(40, 53, 147, 0.4);
}
.default-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.default-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(40, 53, 147, 0.3);
}
.default-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(3, 169, 244, 0.2);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.default-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(3, 169, 244, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.default-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(3, 169, 244, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #03a9f4;
  color: white;
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(3, 169, 244, 0.4);
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.default-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(3, 169, 244, 0.3);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(216, 27, 96, 0.2);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.default-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.default-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(216, 27, 96, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #d81b60;
  color: white;
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(216, 27, 96, 0.4);
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.default-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.default-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(216, 27, 96, 0.3);
}
.default-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-datepicker-toggle-active {
  color: #283593;
}
.default-theme .mat-datepicker-toggle-active.mat-accent {
  color: #03a9f4;
}
.default-theme .mat-datepicker-toggle-active.mat-warn {
  color: #d81b60;
}
.default-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .default-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .default-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .default-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.default-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-expansion-panel-header-description,
.default-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.default-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.default-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.default-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.default-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #283593;
}
.default-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #03a9f4;
}
.default-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #d81b60;
}
.default-theme .mat-focused .mat-form-field-required-marker {
  color: #03a9f4;
}
.default-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #283593;
}
.default-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #03a9f4;
}
.default-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #d81b60;
}
.default-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #283593;
}
.default-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #03a9f4;
}
.default-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #d81b60;
}
.default-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #d81b60;
}
.default-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.default-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #d81b60;
}
.default-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.default-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #d81b60;
}
.default-theme .mat-error {
  color: #d81b60;
}
.default-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.default-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.default-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.default-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.default-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #283593;
}
.default-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #03a9f4;
}
.default-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #d81b60;
}
.default-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #d81b60;
}
.default-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.default-theme .mat-icon.mat-primary {
  color: #283593;
}
.default-theme .mat-icon.mat-accent {
  color: #03a9f4;
}
.default-theme .mat-icon.mat-warn {
  color: #d81b60;
}
.default-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-input-element:disabled,
.default-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-input-element {
  caret-color: #283593;
}
.default-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #03a9f4;
}
.default-theme .mat-form-field.mat-warn .mat-input-element,
.default-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #d81b60;
}
.default-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #d81b60;
}
.default-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-list-item-disabled {
  background-color: #eeeeee;
}
.default-theme .mat-list-option:hover, .default-theme .mat-list-option:focus,
.default-theme .mat-nav-list .mat-list-item:hover,
.default-theme .mat-nav-list .mat-list-item:focus,
.default-theme .mat-action-list .mat-list-item:hover,
.default-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-list-single-selected-option, .default-theme .mat-list-single-selected-option:hover, .default-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-menu-panel {
  background: white;
}
.default-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-menu-item[disabled],
.default-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.default-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-menu-item .mat-icon-no-color,
.default-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-menu-item:hover:not([disabled]),
.default-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.default-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.default-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-paginator {
  background: white;
}
.default-theme .mat-paginator,
.default-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-paginator-decrement,
.default-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.default-theme .mat-paginator-first,
.default-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.default-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.default-theme .mat-icon-button[disabled] .mat-paginator-increment,
.default-theme .mat-icon-button[disabled] .mat-paginator-first,
.default-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.default-theme .mat-progress-bar-background {
  fill: #c6c9e0;
}
.default-theme .mat-progress-bar-buffer {
  background-color: #c6c9e0;
}
.default-theme .mat-progress-bar-fill::after {
  background-color: #283593;
}
.default-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bce6f9;
}
.default-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bce6f9;
}
.default-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #03a9f4;
}
.default-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f2c2d4;
}
.default-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f2c2d4;
}
.default-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #d81b60;
}
.default-theme .mat-progress-spinner circle, .default-theme .mat-spinner circle {
  stroke: #283593;
}
.default-theme .mat-progress-spinner.mat-accent circle, .default-theme .mat-spinner.mat-accent circle {
  stroke: #03a9f4;
}
.default-theme .mat-progress-spinner.mat-warn circle, .default-theme .mat-spinner.mat-warn circle {
  stroke: #d81b60;
}
.default-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #283593;
}
.default-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.default-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .default-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .default-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #283593;
}
.default-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #03a9f4;
}
.default-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.default-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .default-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .default-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #03a9f4;
}
.default-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d81b60;
}
.default-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.default-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .default-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .default-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #d81b60;
}
.default-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.default-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.default-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.default-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.default-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-select-panel {
  background: white;
}
.default-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #283593;
}
.default-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #03a9f4;
}
.default-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #d81b60;
}
.default-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #d81b60;
}
.default-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.default-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.default-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.default-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.default-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #03a9f4;
}
.default-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(3, 169, 244, 0.54);
}
.default-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #03a9f4;
}
.default-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #283593;
}
.default-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(40, 53, 147, 0.54);
}
.default-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #283593;
}
.default-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #d81b60;
}
.default-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(216, 27, 96, 0.54);
}
.default-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #d81b60;
}
.default-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.default-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.default-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-primary .mat-slider-track-fill,
.default-theme .mat-primary .mat-slider-thumb,
.default-theme .mat-primary .mat-slider-thumb-label {
  background-color: #283593;
}
.default-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.default-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(40, 53, 147, 0.2);
}
.default-theme .mat-accent .mat-slider-track-fill,
.default-theme .mat-accent .mat-slider-thumb,
.default-theme .mat-accent .mat-slider-thumb-label {
  background-color: #03a9f4;
}
.default-theme .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.default-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(3, 169, 244, 0.2);
}
.default-theme .mat-warn .mat-slider-track-fill,
.default-theme .mat-warn .mat-slider-thumb,
.default-theme .mat-warn .mat-slider-thumb-label {
  background-color: #d81b60;
}
.default-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.default-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(216, 27, 96, 0.2);
}
.default-theme .mat-slider:hover .mat-slider-track-background,
.default-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-slider-disabled .mat-slider-track-background,
.default-theme .mat-slider-disabled .mat-slider-track-fill,
.default-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.default-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.default-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.default-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .default-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .default-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.default-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.default-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.default-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.default-theme .mat-step-header.cdk-keyboard-focused, .default-theme .mat-step-header.cdk-program-focused, .default-theme .mat-step-header:hover:not([aria-disabled]), .default-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.default-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .default-theme .mat-step-header:hover {
    background: none;
  }
}
.default-theme .mat-step-header .mat-step-label,
.default-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.default-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.default-theme .mat-step-header .mat-step-icon-selected,
.default-theme .mat-step-header .mat-step-icon-state-done,
.default-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #283593;
  color: white;
}
.default-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.default-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.default-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.default-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #03a9f4;
  color: white;
}
.default-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.default-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.default-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.default-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #d81b60;
  color: white;
}
.default-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #d81b60;
}
.default-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #d81b60;
}
.default-theme .mat-stepper-horizontal, .default-theme .mat-stepper-vertical {
  background-color: white;
}
.default-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.default-theme .mat-horizontal-stepper-header::before,
.default-theme .mat-horizontal-stepper-header::after,
.default-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.default-theme .mat-sort-header-arrow {
  color: #757575;
}
.default-theme .mat-tab-nav-bar,
.default-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.default-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.default-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.default-theme .mat-tab-label, .default-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.default-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.default-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.default-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(121, 134, 203, 0.3);
}
.default-theme .mat-tab-group.mat-primary .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #283593;
}
.default-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.default-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 229, 252, 0.3);
}
.default-theme .mat-tab-group.mat-accent .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #03a9f4;
}
.default-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.default-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.default-theme .mat-tab-group.mat-warn .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #d81b60;
}
.default-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .default-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.default-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(121, 134, 203, 0.3);
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .default-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .default-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #283593;
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.default-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 229, 252, 0.3);
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .default-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .default-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #03a9f4;
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.default-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .default-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.default-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 187, 208, 0.3);
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .default-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .default-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #d81b60;
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.default-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.default-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.default-theme .mat-toolbar.mat-primary {
  background: #283593;
  color: white;
}
.default-theme .mat-toolbar.mat-accent {
  background: #03a9f4;
  color: white;
}
.default-theme .mat-toolbar.mat-warn {
  background: #d81b60;
  color: white;
}
.default-theme .mat-toolbar .mat-form-field-underline,
.default-theme .mat-toolbar .mat-form-field-ripple,
.default-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.default-theme .mat-toolbar .mat-form-field-label,
.default-theme .mat-toolbar .mat-focused .mat-form-field-label,
.default-theme .mat-toolbar .mat-select-value,
.default-theme .mat-toolbar .mat-select-arrow,
.default-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.default-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.default-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.default-theme .mat-tree {
  background: white;
}
.default-theme .mat-tree-node,
.default-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.default-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.default-theme .mat-simple-snackbar-action {
  color: #03a9f4;
}
.default-theme a {
  color: rgba(0, 0, 0, 0.87);
}
.default-theme a:hover {
  color: #03a9f4;
}
.default-theme code {
  color: rgba(0, 0, 0, 0.87);
  background-color: #b3e5fc;
}
.default-theme p a {
  color: #03a9f4;
}
.default-theme p a:hover {
  color: #0288d1;
}
.default-theme mat-sidenav-container mat-toolbar button.active {
  color: white;
  background-color: #03a9f4;
}
.default-theme mat-sidenav-container mat-toolbar .link {
  color: white;
}
.default-theme mat-sidenav-container mat-sidenav .mat-list-item.active {
  color: white;
  background-color: #03a9f4 !important;
}
.default-theme mat-sidenav-container mat-sidenav .mat-list-item.active:hover {
  background-color: #0288d1 !important;
}
.default-theme mat-sidenav-container .footer {
  color: #7986cb;
  background-color: #1a237e;
}
.default-theme mat-sidenav-container .footer .links a,
.default-theme mat-sidenav-container .footer .signature a {
  color: #7986cb;
}
.default-theme mat-sidenav-container .footer .links a:hover,
.default-theme mat-sidenav-container .footer .signature a:hover {
  color: #03a9f4;
}
.default-theme .error-notification-overlay {
  color: white;
  background-color: #d81b60;
}
.default-theme .info-notification-overlay {
  color: white;
  background-color: #03a9f4;
}
.default-theme .success-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.default-theme .warning-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.default-theme anms-about .background {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}
.default-theme anms-about .background .gradient::before {
  background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}
.default-theme anms-about .follow-releases p,
.default-theme anms-about .contributors p {
  background-color: #fafafa;
}
.default-theme anms-about .follow-releases p a,
.default-theme anms-about .contributors p a {
  color: #03a9f4;
}
.default-theme anms-about .follow-releases p a:hover,
.default-theme anms-about .contributors p a:hover {
  color: #0288d1;
}
.default-theme anms-about .contributors img {
  border-color: #fafafa !important;
}
.default-theme anms-big-input .big-input input {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.default-theme anms-big-input .big-input.has-focus {
  box-shadow: 0 0 15px 2px rgba(3, 169, 244, 0.4), 0 0 15px 2px rgba(3, 169, 244, 0.4), 0 0 15px 2px rgba(3, 169, 244, 0.4);
}
.default-theme .error {
  color: white;
  background-color: #d81b60;
}
.default-theme .information {
  color: white;
  background-color: #03a9f4;
}
.default-theme .success {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.default-theme .warning {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.default-theme .todos-filter-menu-overlay .active {
  color: white;
  background-color: #03a9f4;
}
.default-theme .todos-filter-menu-overlay .active .fa-icon {
  color: white;
}
.default-theme .todos-filter-menu-overlay .active:hover {
  color: white;
  background-color: #03a9f4;
}
.default-theme .todos-notification-overlay .mat-simple-snackbar {
  align-items: center;
}
.default-theme .todos-notification-overlay .mat-simple-snackbar button {
  border-radius: 2px;
  background-color: #03a9f4;
  color: white;
}
.default-theme anms-stock-market mat-card .negative {
  color: #d81b60;
}
.default-theme anms-stock-market .error-state fa-icon {
  color: #d81b60;
}
.default-theme anms-parent > .container > .row > .col-md-6 > .example {
  border-color: #03a9f4;
}
.default-theme anms-parent > .container > .row > .col-md-6 > .example > h1 {
  color: #03a9f4;
}
.default-theme anms-child > div {
  border-color: #d81b60;
}
.default-theme anms-child > div > h2 {
  color: #d81b60;
}
.default-theme anms-examples .nav-responsive {
  border-bottom: 1px solid #283593;
}
.default-theme anms-examples .nav-responsive mat-select {
  line-height: 48px;
  margin: 0 20px;
  width: 100%;
}
.default-theme .nav-responsive-overlay .active {
  color: white;
  background-color: #03a9f4;
}

.light-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-option:hover:not(.mat-option-disabled), .light-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #eeeeee;
}
.light-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bcaaa4;
}
.light-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffab91;
}
.light-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.light-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.light-theme .mat-primary .mat-pseudo-checkbox-checked,
.light-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #eeeeee;
}
.light-theme .mat-pseudo-checkbox-checked,
.light-theme .mat-pseudo-checkbox-indeterminate,
.light-theme .mat-accent .mat-pseudo-checkbox-checked,
.light-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #bcaaa4;
}
.light-theme .mat-warn .mat-pseudo-checkbox-checked,
.light-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ffab91;
}
.light-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.light-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.light-theme .mat-app-background, .light-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.light-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-badge {
  position: relative;
}
.light-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.light-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.light-theme .ng-animate-disabled .mat-badge-content,
.light-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.light-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.light-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.light-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.light-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.light-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.light-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.light-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.light-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.light-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.light-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.light-theme .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #eeeeee;
}
.cdk-high-contrast-active .light-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.light-theme .mat-badge-accent .mat-badge-content {
  background: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-badge-warn .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #ffab91;
}
.light-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-button, .light-theme .mat-icon-button, .light-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.light-theme .mat-button.mat-primary, .light-theme .mat-icon-button.mat-primary, .light-theme .mat-stroked-button.mat-primary {
  color: #eeeeee;
}
.light-theme .mat-button.mat-accent, .light-theme .mat-icon-button.mat-accent, .light-theme .mat-stroked-button.mat-accent {
  color: #bcaaa4;
}
.light-theme .mat-button.mat-warn, .light-theme .mat-icon-button.mat-warn, .light-theme .mat-stroked-button.mat-warn {
  color: #ffab91;
}
.light-theme .mat-button.mat-primary.mat-button-disabled, .light-theme .mat-button.mat-accent.mat-button-disabled, .light-theme .mat-button.mat-warn.mat-button-disabled, .light-theme .mat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-icon-button.mat-primary.mat-button-disabled, .light-theme .mat-icon-button.mat-accent.mat-button-disabled, .light-theme .mat-icon-button.mat-warn.mat-button-disabled, .light-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-stroked-button.mat-primary.mat-button-disabled, .light-theme .mat-stroked-button.mat-accent.mat-button-disabled, .light-theme .mat-stroked-button.mat-warn.mat-button-disabled, .light-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #eeeeee;
}
.light-theme .mat-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #bcaaa4;
}
.light-theme .mat-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ffab91;
}
.light-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.light-theme .mat-button .mat-ripple-element, .light-theme .mat-icon-button .mat-ripple-element, .light-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.light-theme .mat-button-focus-overlay {
  background: black;
}
.light-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-flat-button, .light-theme .mat-raised-button, .light-theme .mat-fab, .light-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-flat-button.mat-primary.mat-button-disabled, .light-theme .mat-flat-button.mat-accent.mat-button-disabled, .light-theme .mat-flat-button.mat-warn.mat-button-disabled, .light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-raised-button.mat-primary.mat-button-disabled, .light-theme .mat-raised-button.mat-accent.mat-button-disabled, .light-theme .mat-raised-button.mat-warn.mat-button-disabled, .light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-fab.mat-primary.mat-button-disabled, .light-theme .mat-fab.mat-accent.mat-button-disabled, .light-theme .mat-fab.mat-warn.mat-button-disabled, .light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
  background-color: #eeeeee;
}
.light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
  background-color: #bcaaa4;
}
.light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
  background-color: #ffab91;
}
.light-theme .mat-flat-button.mat-primary.mat-button-disabled, .light-theme .mat-flat-button.mat-accent.mat-button-disabled, .light-theme .mat-flat-button.mat-warn.mat-button-disabled, .light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-raised-button.mat-primary.mat-button-disabled, .light-theme .mat-raised-button.mat-accent.mat-button-disabled, .light-theme .mat-raised-button.mat-warn.mat-button-disabled, .light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-fab.mat-primary.mat-button-disabled, .light-theme .mat-fab.mat-accent.mat-button-disabled, .light-theme .mat-fab.mat-warn.mat-button-disabled, .light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-flat-button.mat-primary .mat-ripple-element, .light-theme .mat-raised-button.mat-primary .mat-ripple-element, .light-theme .mat-fab.mat-primary .mat-ripple-element, .light-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-flat-button.mat-accent .mat-ripple-element, .light-theme .mat-raised-button.mat-accent .mat-ripple-element, .light-theme .mat-fab.mat-accent .mat-ripple-element, .light-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-flat-button.mat-warn .mat-ripple-element, .light-theme .mat-raised-button.mat-warn .mat-ripple-element, .light-theme .mat-fab.mat-warn .mat-ripple-element, .light-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-stroked-button:not([class*=mat-elevation-z]), .light-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-standalone,
.light-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.light-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.light-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.light-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.light-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.light-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.light-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.light-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.light-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.light-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.light-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #eeeeee;
}
.light-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #bcaaa4;
}
.light-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ffab91;
}
.light-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .light-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.light-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.light-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #eeeeee;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #bcaaa4;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ffab91;
}
.light-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-table {
  background: white;
}
.light-theme .mat-table thead, .light-theme .mat-table tbody, .light-theme .mat-table tfoot,
.light-theme mat-header-row, .light-theme mat-row, .light-theme mat-footer-row,
.light-theme [mat-header-row], .light-theme [mat-row], .light-theme [mat-footer-row],
.light-theme .mat-table-sticky {
  background: inherit;
}
.light-theme mat-row, .light-theme mat-header-row, .light-theme mat-footer-row,
.light-theme th.mat-header-cell, .light-theme td.mat-cell, .light-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-cell, .light-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-datepicker-toggle,
.light-theme .mat-datepicker-content .mat-calendar-next-button,
.light-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-calendar-table-header,
.light-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-calendar-body-cell-content,
.light-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.light-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.light-theme .mat-calendar-body-in-range::before {
  background: rgba(238, 238, 238, 0.2);
}
.light-theme .mat-calendar-body-comparison-identical,
.light-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-calendar-body-comparison-bridge-start::before,
.light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-calendar-body-comparison-bridge-end::before,
.light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(238, 238, 238, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-calendar-body-selected {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(238, 238, 238, 0.4);
}
.light-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.light-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(238, 238, 238, 0.3);
}
.light-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(188, 170, 164, 0.2);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(188, 170, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(188, 170, 164, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(188, 170, 164, 0.4);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(188, 170, 164, 0.3);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 171, 145, 0.2);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 171, 145, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 171, 145, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 171, 145, 0.4);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 171, 145, 0.3);
}
.light-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-datepicker-toggle-active {
  color: #eeeeee;
}
.light-theme .mat-datepicker-toggle-active.mat-accent {
  color: #bcaaa4;
}
.light-theme .mat-datepicker-toggle-active.mat-warn {
  color: #ffab91;
}
.light-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .light-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.light-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-expansion-panel-header-description,
.light-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.light-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #eeeeee;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #bcaaa4;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ffab91;
}
.light-theme .mat-focused .mat-form-field-required-marker {
  color: #bcaaa4;
}
.light-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #eeeeee;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #bcaaa4;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ffab91;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #eeeeee;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #bcaaa4;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ffab91;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ffab91;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ffab91;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ffab91;
}
.light-theme .mat-error {
  color: #ffab91;
}
.light-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.light-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.light-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #eeeeee;
}
.light-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #bcaaa4;
}
.light-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ffab91;
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ffab91;
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.light-theme .mat-icon.mat-primary {
  color: #eeeeee;
}
.light-theme .mat-icon.mat-accent {
  color: #bcaaa4;
}
.light-theme .mat-icon.mat-warn {
  color: #ffab91;
}
.light-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-input-element:disabled,
.light-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-input-element {
  caret-color: #eeeeee;
}
.light-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #bcaaa4;
}
.light-theme .mat-form-field.mat-warn .mat-input-element,
.light-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #ffab91;
}
.light-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ffab91;
}
.light-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-list-item-disabled {
  background-color: #eeeeee;
}
.light-theme .mat-list-option:hover, .light-theme .mat-list-option:focus,
.light-theme .mat-nav-list .mat-list-item:hover,
.light-theme .mat-nav-list .mat-list-item:focus,
.light-theme .mat-action-list .mat-list-item:hover,
.light-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-list-single-selected-option, .light-theme .mat-list-single-selected-option:hover, .light-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-menu-panel {
  background: white;
}
.light-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-menu-item[disabled],
.light-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.light-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-menu-item .mat-icon-no-color,
.light-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-menu-item:hover:not([disabled]),
.light-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.light-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.light-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-paginator {
  background: white;
}
.light-theme .mat-paginator,
.light-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-paginator-decrement,
.light-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme .mat-paginator-first,
.light-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.light-theme .mat-icon-button[disabled] .mat-paginator-increment,
.light-theme .mat-icon-button[disabled] .mat-paginator-first,
.light-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-progress-bar-background {
  fill: #f7f7f7;
}
.light-theme .mat-progress-bar-buffer {
  background-color: #f7f7f7;
}
.light-theme .mat-progress-bar-fill::after {
  background-color: #eeeeee;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ebe6e5;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ebe6e5;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #bcaaa4;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbe6e0;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbe6e0;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ffab91;
}
.light-theme .mat-progress-spinner circle, .light-theme .mat-spinner circle {
  stroke: #eeeeee;
}
.light-theme .mat-progress-spinner.mat-accent circle, .light-theme .mat-spinner.mat-accent circle {
  stroke: #bcaaa4;
}
.light-theme .mat-progress-spinner.mat-warn circle, .light-theme .mat-spinner.mat-warn circle {
  stroke: #ffab91;
}
.light-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #eeeeee;
}
.light-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #eeeeee;
}
.light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bcaaa4;
}
.light-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #bcaaa4;
}
.light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffab91;
}
.light-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ffab91;
}
.light-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-select-panel {
  background: white;
}
.light-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #eeeeee;
}
.light-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #bcaaa4;
}
.light-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ffab91;
}
.light-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ffab91;
}
.light-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.light-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #bcaaa4;
}
.light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(188, 170, 164, 0.54);
}
.light-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #bcaaa4;
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #eeeeee;
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(238, 238, 238, 0.54);
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #eeeeee;
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab91;
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 145, 0.54);
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ffab91;
}
.light-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.light-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-primary .mat-slider-track-fill,
.light-theme .mat-primary .mat-slider-thumb,
.light-theme .mat-primary .mat-slider-thumb-label {
  background-color: #eeeeee;
}
.light-theme .mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(238, 238, 238, 0.2);
}
.light-theme .mat-accent .mat-slider-track-fill,
.light-theme .mat-accent .mat-slider-thumb,
.light-theme .mat-accent .mat-slider-thumb-label {
  background-color: #bcaaa4;
}
.light-theme .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(188, 170, 164, 0.2);
}
.light-theme .mat-warn .mat-slider-track-fill,
.light-theme .mat-warn .mat-slider-thumb,
.light-theme .mat-warn .mat-slider-thumb-label {
  background-color: #ffab91;
}
.light-theme .mat-warn .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 171, 145, 0.2);
}
.light-theme .mat-slider:hover .mat-slider-track-background,
.light-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider-disabled .mat-slider-track-background,
.light-theme .mat-slider-disabled .mat-slider-track-fill,
.light-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.light-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme .mat-step-header.cdk-keyboard-focused, .light-theme .mat-step-header.cdk-program-focused, .light-theme .mat-step-header:hover:not([aria-disabled]), .light-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .light-theme .mat-step-header:hover {
    background: none;
  }
}
.light-theme .mat-step-header .mat-step-label,
.light-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header .mat-step-icon-selected,
.light-theme .mat-step-header .mat-step-icon-state-done,
.light-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.light-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.light-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-warn .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.light-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.light-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ffab91;
}
.light-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ffab91;
}
.light-theme .mat-stepper-horizontal, .light-theme .mat-stepper-vertical {
  background-color: white;
}
.light-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-horizontal-stepper-header::before,
.light-theme .mat-horizontal-stepper-header::after,
.light-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-sort-header-arrow {
  color: #757575;
}
.light-theme .mat-tab-nav-bar,
.light-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.light-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.light-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.light-theme .mat-tab-label, .light-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.light-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(158, 158, 158, 0.3);
}
.light-theme .mat-tab-group.mat-primary .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #eeeeee;
}
.light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 204, 200, 0.3);
}
.light-theme .mat-tab-group.mat-accent .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #bcaaa4;
}
.light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.light-theme .mat-tab-group.mat-warn .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ffab91;
}
.light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(158, 158, 158, 0.3);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #eeeeee;
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(215, 204, 200, 0.3);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #bcaaa4;
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ffab91;
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar.mat-primary {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar.mat-accent {
  background: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar.mat-warn {
  background: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar .mat-form-field-underline,
.light-theme .mat-toolbar .mat-form-field-ripple,
.light-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.light-theme .mat-toolbar .mat-form-field-label,
.light-theme .mat-toolbar .mat-focused .mat-form-field-label,
.light-theme .mat-toolbar .mat-select-value,
.light-theme .mat-toolbar .mat-select-arrow,
.light-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.light-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.light-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.light-theme .mat-tree {
  background: white;
}
.light-theme .mat-tree-node,
.light-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-simple-snackbar-action {
  color: #bcaaa4;
}
.light-theme a {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme a:hover {
  color: #bcaaa4;
}
.light-theme code {
  color: rgba(0, 0, 0, 0.87);
  background-color: #d7ccc8;
}
.light-theme p a {
  color: #bcaaa4;
}
.light-theme p a:hover {
  color: #5d4037;
}
.light-theme mat-sidenav-container mat-toolbar button.active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}
.light-theme mat-sidenav-container mat-toolbar .link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme mat-sidenav-container mat-sidenav .mat-list-item.active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4 !important;
}
.light-theme mat-sidenav-container mat-sidenav .mat-list-item.active:hover {
  background-color: #5d4037 !important;
}
.light-theme mat-sidenav-container .footer {
  color: #9e9e9e;
  background-color: #e0e0e0;
}
.light-theme mat-sidenav-container .footer .links a,
.light-theme mat-sidenav-container .footer .signature a {
  color: #9e9e9e;
}
.light-theme mat-sidenav-container .footer .links a:hover,
.light-theme mat-sidenav-container .footer .signature a:hover {
  color: #bcaaa4;
}
.light-theme .error-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffab91;
}
.light-theme .info-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}
.light-theme .success-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.light-theme .warning-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.light-theme anms-about .background {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}
.light-theme anms-about .background .gradient::before {
  background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}
.light-theme anms-about .follow-releases p,
.light-theme anms-about .contributors p {
  background-color: #fafafa;
}
.light-theme anms-about .follow-releases p a,
.light-theme anms-about .contributors p a {
  color: #bcaaa4;
}
.light-theme anms-about .follow-releases p a:hover,
.light-theme anms-about .contributors p a:hover {
  color: #5d4037;
}
.light-theme anms-about .contributors img {
  border-color: #fafafa !important;
}
.light-theme anms-big-input .big-input input {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.light-theme anms-big-input .big-input.has-focus {
  box-shadow: 0 0 15px 2px rgba(188, 170, 164, 0.4), 0 0 15px 2px rgba(188, 170, 164, 0.4), 0 0 15px 2px rgba(188, 170, 164, 0.4);
}
.light-theme .error {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffab91;
}
.light-theme .information {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}
.light-theme .success {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.light-theme .warning {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.light-theme .todos-filter-menu-overlay .active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}
.light-theme .todos-filter-menu-overlay .active .fa-icon {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .todos-filter-menu-overlay .active:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}
.light-theme .todos-notification-overlay .mat-simple-snackbar {
  align-items: center;
}
.light-theme .todos-notification-overlay .mat-simple-snackbar button {
  border-radius: 2px;
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme anms-stock-market mat-card .negative {
  color: #ffab91;
}
.light-theme anms-stock-market .error-state fa-icon {
  color: #ffab91;
}
.light-theme anms-parent > .container > .row > .col-md-6 > .example {
  border-color: #bcaaa4;
}
.light-theme anms-parent > .container > .row > .col-md-6 > .example > h1 {
  color: #bcaaa4;
}
.light-theme anms-child > div {
  border-color: #ffab91;
}
.light-theme anms-child > div > h2 {
  color: #ffab91;
}
.light-theme anms-examples .nav-responsive {
  border-bottom: 1px solid #eeeeee;
}
.light-theme anms-examples .nav-responsive mat-select {
  line-height: 48px;
  margin: 0 20px;
  width: 100%;
}
.light-theme .nav-responsive-overlay .active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #bcaaa4;
}

.black-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-option {
  color: white;
}
.black-theme .mat-option:hover:not(.mat-option-disabled), .black-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.black-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.black-theme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.black-theme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #616161;
}
.black-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #78909c;
}
.black-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.black-theme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-pseudo-checkbox::after {
  color: #303030;
}
.black-theme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.black-theme .mat-primary .mat-pseudo-checkbox-checked,
.black-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #616161;
}
.black-theme .mat-pseudo-checkbox-checked,
.black-theme .mat-pseudo-checkbox-indeterminate,
.black-theme .mat-accent .mat-pseudo-checkbox-checked,
.black-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #78909c;
}
.black-theme .mat-warn .mat-pseudo-checkbox-checked,
.black-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.black-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.black-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.black-theme .mat-app-background, .black-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.black-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.black-theme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.black-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.black-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.black-theme .mat-badge {
  position: relative;
}
.black-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.black-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.black-theme .ng-animate-disabled .mat-badge-content,
.black-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.black-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.black-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.black-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.black-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.black-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .black-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.black-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .black-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.black-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .black-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.black-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .black-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.black-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.black-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.black-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.black-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .black-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.black-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .black-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .black-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.black-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.black-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.black-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.black-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .black-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.black-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .black-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.black-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .black-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.black-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .black-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.black-theme .mat-badge-content {
  color: white;
  background: #616161;
}
.cdk-high-contrast-active .black-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.black-theme .mat-badge-accent .mat-badge-content {
  background: #78909c;
  color: white;
}
.black-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.black-theme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.black-theme .mat-button, .black-theme .mat-icon-button, .black-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.black-theme .mat-button.mat-primary, .black-theme .mat-icon-button.mat-primary, .black-theme .mat-stroked-button.mat-primary {
  color: #616161;
}
.black-theme .mat-button.mat-accent, .black-theme .mat-icon-button.mat-accent, .black-theme .mat-stroked-button.mat-accent {
  color: #78909c;
}
.black-theme .mat-button.mat-warn, .black-theme .mat-icon-button.mat-warn, .black-theme .mat-stroked-button.mat-warn {
  color: #f44336;
}
.black-theme .mat-button.mat-primary.mat-button-disabled, .black-theme .mat-button.mat-accent.mat-button-disabled, .black-theme .mat-button.mat-warn.mat-button-disabled, .black-theme .mat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-icon-button.mat-primary.mat-button-disabled, .black-theme .mat-icon-button.mat-accent.mat-button-disabled, .black-theme .mat-icon-button.mat-warn.mat-button-disabled, .black-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-stroked-button.mat-primary.mat-button-disabled, .black-theme .mat-stroked-button.mat-accent.mat-button-disabled, .black-theme .mat-stroked-button.mat-warn.mat-button-disabled, .black-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-button.mat-primary .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #616161;
}
.black-theme .mat-button.mat-accent .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #78909c;
}
.black-theme .mat-button.mat-warn .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.black-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .black-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .black-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.black-theme .mat-button .mat-ripple-element, .black-theme .mat-icon-button .mat-ripple-element, .black-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.black-theme .mat-button-focus-overlay {
  background: white;
}
.black-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-flat-button, .black-theme .mat-raised-button, .black-theme .mat-fab, .black-theme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.black-theme .mat-flat-button.mat-primary, .black-theme .mat-raised-button.mat-primary, .black-theme .mat-fab.mat-primary, .black-theme .mat-mini-fab.mat-primary {
  color: white;
}
.black-theme .mat-flat-button.mat-accent, .black-theme .mat-raised-button.mat-accent, .black-theme .mat-fab.mat-accent, .black-theme .mat-mini-fab.mat-accent {
  color: white;
}
.black-theme .mat-flat-button.mat-warn, .black-theme .mat-raised-button.mat-warn, .black-theme .mat-fab.mat-warn, .black-theme .mat-mini-fab.mat-warn {
  color: white;
}
.black-theme .mat-flat-button.mat-primary.mat-button-disabled, .black-theme .mat-flat-button.mat-accent.mat-button-disabled, .black-theme .mat-flat-button.mat-warn.mat-button-disabled, .black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-raised-button.mat-primary.mat-button-disabled, .black-theme .mat-raised-button.mat-accent.mat-button-disabled, .black-theme .mat-raised-button.mat-warn.mat-button-disabled, .black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-fab.mat-primary.mat-button-disabled, .black-theme .mat-fab.mat-accent.mat-button-disabled, .black-theme .mat-fab.mat-warn.mat-button-disabled, .black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-flat-button.mat-primary, .black-theme .mat-raised-button.mat-primary, .black-theme .mat-fab.mat-primary, .black-theme .mat-mini-fab.mat-primary {
  background-color: #616161;
}
.black-theme .mat-flat-button.mat-accent, .black-theme .mat-raised-button.mat-accent, .black-theme .mat-fab.mat-accent, .black-theme .mat-mini-fab.mat-accent {
  background-color: #78909c;
}
.black-theme .mat-flat-button.mat-warn, .black-theme .mat-raised-button.mat-warn, .black-theme .mat-fab.mat-warn, .black-theme .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.black-theme .mat-flat-button.mat-primary.mat-button-disabled, .black-theme .mat-flat-button.mat-accent.mat-button-disabled, .black-theme .mat-flat-button.mat-warn.mat-button-disabled, .black-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-raised-button.mat-primary.mat-button-disabled, .black-theme .mat-raised-button.mat-accent.mat-button-disabled, .black-theme .mat-raised-button.mat-warn.mat-button-disabled, .black-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .black-theme .mat-fab.mat-primary.mat-button-disabled, .black-theme .mat-fab.mat-accent.mat-button-disabled, .black-theme .mat-fab.mat-warn.mat-button-disabled, .black-theme .mat-fab.mat-button-disabled.mat-button-disabled, .black-theme .mat-mini-fab.mat-primary.mat-button-disabled, .black-theme .mat-mini-fab.mat-accent.mat-button-disabled, .black-theme .mat-mini-fab.mat-warn.mat-button-disabled, .black-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-flat-button.mat-primary .mat-ripple-element, .black-theme .mat-raised-button.mat-primary .mat-ripple-element, .black-theme .mat-fab.mat-primary .mat-ripple-element, .black-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-flat-button.mat-accent .mat-ripple-element, .black-theme .mat-raised-button.mat-accent .mat-ripple-element, .black-theme .mat-fab.mat-accent .mat-ripple-element, .black-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-flat-button.mat-warn .mat-ripple-element, .black-theme .mat-raised-button.mat-warn .mat-ripple-element, .black-theme .mat-fab.mat-warn .mat-ripple-element, .black-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-stroked-button:not([class*=mat-elevation-z]), .black-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-fab:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .black-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-button-toggle-standalone,
.black-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.black-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.black-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.black-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.black-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.black-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.black-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.black-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.black-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.black-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme .mat-card {
  background: #424242;
  color: white;
}
.black-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-checkbox-checkmark {
  fill: #303030;
}
.black-theme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.black-theme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.black-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #616161;
}
.black-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #78909c;
}
.black-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .black-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.black-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .black-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.black-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.black-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #616161;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #78909c;
}
.black-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.black-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.black-theme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip::after {
  background: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #616161;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #78909c;
  color: white;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.black-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-table {
  background: #424242;
}
.black-theme .mat-table thead, .black-theme .mat-table tbody, .black-theme .mat-table tfoot,
.black-theme mat-header-row, .black-theme mat-row, .black-theme mat-footer-row,
.black-theme [mat-header-row], .black-theme [mat-row], .black-theme [mat-footer-row],
.black-theme .mat-table-sticky {
  background: inherit;
}
.black-theme mat-row, .black-theme mat-header-row, .black-theme mat-footer-row,
.black-theme th.mat-header-cell, .black-theme td.mat-cell, .black-theme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-cell, .black-theme .mat-footer-cell {
  color: white;
}
.black-theme .mat-calendar-arrow {
  fill: white;
}
.black-theme .mat-datepicker-toggle,
.black-theme .mat-datepicker-content .mat-calendar-next-button,
.black-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.black-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-calendar-table-header,
.black-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-calendar-body-cell-content,
.black-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.black-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-calendar-body-in-range::before {
  background: rgba(97, 97, 97, 0.2);
}
.black-theme .mat-calendar-body-comparison-identical,
.black-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-calendar-body-comparison-bridge-start::before,
.black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-calendar-body-comparison-bridge-end::before,
.black-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-calendar-body-selected {
  background-color: #616161;
  color: white;
}
.black-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(97, 97, 97, 0.4);
}
.black-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.black-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(97, 97, 97, 0.3);
}
.black-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(120, 144, 156, 0.2);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(120, 144, 156, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.black-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(120, 144, 156, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #78909c;
  color: white;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(120, 144, 156, 0.4);
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.black-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(120, 144, 156, 0.3);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.black-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.black-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.black-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
.black-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-datepicker-toggle-active {
  color: #616161;
}
.black-theme .mat-datepicker-toggle-active.mat-accent {
  color: #78909c;
}
.black-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.black-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.black-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.black-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .black-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .black-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .black-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.black-theme .mat-expansion-panel-header-title {
  color: white;
}
.black-theme .mat-expansion-panel-header-description,
.black-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.black-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.black-theme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #616161;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #78909c;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.black-theme .mat-focused .mat-form-field-required-marker {
  color: #78909c;
}
.black-theme .mat-form-field-ripple {
  background-color: white;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #616161;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #78909c;
}
.black-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #616161;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #78909c;
}
.black-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.black-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.black-theme .mat-error {
  color: #f44336;
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.black-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.black-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.black-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #616161;
}
.black-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #78909c;
}
.black-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.black-theme .mat-icon.mat-primary {
  color: #616161;
}
.black-theme .mat-icon.mat-accent {
  color: #78909c;
}
.black-theme .mat-icon.mat-warn {
  color: #f44336;
}
.black-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-input-element:disabled,
.black-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-input-element {
  caret-color: #616161;
}
.black-theme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.black-theme .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.black-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #78909c;
}
.black-theme .mat-form-field.mat-warn .mat-input-element,
.black-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.black-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.black-theme .mat-list-base .mat-list-item {
  color: white;
}
.black-theme .mat-list-base .mat-list-option {
  color: white;
}
.black-theme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-list-item-disabled {
  background-color: black;
}
.black-theme .mat-list-option:hover, .black-theme .mat-list-option:focus,
.black-theme .mat-nav-list .mat-list-item:hover,
.black-theme .mat-nav-list .mat-list-item:focus,
.black-theme .mat-action-list .mat-list-item:hover,
.black-theme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.black-theme .mat-list-single-selected-option, .black-theme .mat-list-single-selected-option:hover, .black-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-menu-panel {
  background: #424242;
}
.black-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-menu-item {
  background: transparent;
  color: white;
}
.black-theme .mat-menu-item[disabled],
.black-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.black-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-menu-item .mat-icon-no-color,
.black-theme .mat-menu-submenu-icon {
  color: white;
}
.black-theme .mat-menu-item:hover:not([disabled]),
.black-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.black-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.black-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.black-theme .mat-paginator {
  background: #424242;
}
.black-theme .mat-paginator,
.black-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-paginator-decrement,
.black-theme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.black-theme .mat-paginator-first,
.black-theme .mat-paginator-last {
  border-top: 2px solid white;
}
.black-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.black-theme .mat-icon-button[disabled] .mat-paginator-increment,
.black-theme .mat-icon-button[disabled] .mat-paginator-first,
.black-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-progress-bar-background {
  fill: #3c3c3c;
}
.black-theme .mat-progress-bar-buffer {
  background-color: #3c3c3c;
}
.black-theme .mat-progress-bar-fill::after {
  background-color: #616161;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #42484b;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #42484b;
}
.black-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #78909c;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #613532;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #613532;
}
.black-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.black-theme .mat-progress-spinner circle, .black-theme .mat-spinner circle {
  stroke: #616161;
}
.black-theme .mat-progress-spinner.mat-accent circle, .black-theme .mat-spinner.mat-accent circle {
  stroke: #78909c;
}
.black-theme .mat-progress-spinner.mat-warn circle, .black-theme .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.black-theme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #616161;
}
.black-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #616161;
}
.black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #78909c;
}
.black-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #78909c;
}
.black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.black-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.black-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .black-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .black-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.black-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.black-theme .mat-select-value {
  color: white;
}
.black-theme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-select-panel {
  background: #424242;
}
.black-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #616161;
}
.black-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #78909c;
}
.black-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.black-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.black-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.black-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
.black-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.black-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.black-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.black-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.black-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #78909c;
}
.black-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(120, 144, 156, 0.54);
}
.black-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #78909c;
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #616161;
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(97, 97, 97, 0.54);
}
.black-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #616161;
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.black-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.black-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.black-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.black-theme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-primary .mat-slider-track-fill,
.black-theme .mat-primary .mat-slider-thumb,
.black-theme .mat-primary .mat-slider-thumb-label {
  background-color: #616161;
}
.black-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.black-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(97, 97, 97, 0.2);
}
.black-theme .mat-accent .mat-slider-track-fill,
.black-theme .mat-accent .mat-slider-thumb,
.black-theme .mat-accent .mat-slider-thumb-label {
  background-color: #78909c;
}
.black-theme .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.black-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(120, 144, 156, 0.2);
}
.black-theme .mat-warn .mat-slider-track-fill,
.black-theme .mat-warn .mat-slider-thumb,
.black-theme .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.black-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.black-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.black-theme .mat-slider:hover .mat-slider-track-background,
.black-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-disabled .mat-slider-track-background,
.black-theme .mat-slider-disabled .mat-slider-track-fill,
.black-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.black-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.black-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.black-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.black-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .black-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .black-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.black-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.black-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.black-theme .mat-step-header.cdk-keyboard-focused, .black-theme .mat-step-header.cdk-program-focused, .black-theme .mat-step-header:hover:not([aria-disabled]), .black-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.black-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .black-theme .mat-step-header:hover {
    background: none;
  }
}
.black-theme .mat-step-header .mat-step-label,
.black-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.black-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.black-theme .mat-step-header .mat-step-icon-selected,
.black-theme .mat-step-header .mat-step-icon-state-done,
.black-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #616161;
  color: white;
}
.black-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.black-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.black-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.black-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #78909c;
  color: white;
}
.black-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.black-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.black-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.black-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.black-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.black-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.black-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.black-theme .mat-stepper-horizontal, .black-theme .mat-stepper-vertical {
  background-color: #424242;
}
.black-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-horizontal-stepper-header::before,
.black-theme .mat-horizontal-stepper-header::after,
.black-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.black-theme .mat-tab-nav-bar,
.black-theme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.black-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.black-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.black-theme .mat-tab-label, .black-theme .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.black-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.black-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.black-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 224, 224, 0.3);
}
.black-theme .mat-tab-group.mat-primary .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #616161;
}
.black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.black-theme .mat-tab-group.mat-accent .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #78909c;
}
.black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.black-theme .mat-tab-group.mat-warn .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .black-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 224, 224, 0.3);
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #616161;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #78909c;
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.black-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.black-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.black-theme .mat-toolbar {
  background: #212121;
  color: white;
}
.black-theme .mat-toolbar.mat-primary {
  background: #616161;
  color: white;
}
.black-theme .mat-toolbar.mat-accent {
  background: #78909c;
  color: white;
}
.black-theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.black-theme .mat-toolbar .mat-form-field-underline,
.black-theme .mat-toolbar .mat-form-field-ripple,
.black-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.black-theme .mat-toolbar .mat-form-field-label,
.black-theme .mat-toolbar .mat-focused .mat-form-field-label,
.black-theme .mat-toolbar .mat-select-value,
.black-theme .mat-toolbar .mat-select-arrow,
.black-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.black-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.black-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.black-theme .mat-tree {
  background: #424242;
}
.black-theme .mat-tree-node,
.black-theme .mat-nested-tree-node {
  color: white;
}
.black-theme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.black-theme .mat-simple-snackbar-action {
  color: inherit;
}
.black-theme a {
  color: white;
}
.black-theme a:hover {
  color: #78909c;
}
.black-theme code {
  color: rgba(0, 0, 0, 0.87);
  background-color: #cfd8dc;
}
.black-theme p a {
  color: #78909c;
}
.black-theme p a:hover {
  color: #455a64;
}
.black-theme mat-sidenav-container mat-toolbar button.active {
  color: white;
  background-color: #78909c;
}
.black-theme mat-sidenav-container mat-toolbar .link {
  color: white;
}
.black-theme mat-sidenav-container mat-sidenav .mat-list-item.active {
  color: white;
  background-color: #78909c !important;
}
.black-theme mat-sidenav-container mat-sidenav .mat-list-item.active:hover {
  background-color: #455a64 !important;
}
.black-theme mat-sidenav-container .footer {
  color: #e0e0e0;
  background-color: #212121;
}
.black-theme mat-sidenav-container .footer .links a,
.black-theme mat-sidenav-container .footer .signature a {
  color: #e0e0e0;
}
.black-theme mat-sidenav-container .footer .links a:hover,
.black-theme mat-sidenav-container .footer .signature a:hover {
  color: #78909c;
}
.black-theme .error-notification-overlay {
  color: white;
  background-color: #f44336;
}
.black-theme .info-notification-overlay {
  color: white;
  background-color: #78909c;
}
.black-theme .success-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.black-theme .warning-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.black-theme anms-about .background {
  color: white;
  background-color: #303030;
}
.black-theme anms-about .background .gradient::before {
  background: linear-gradient(to bottom, rgba(48, 48, 48, 0) 0%, #303030 100%);
}
.black-theme anms-about .follow-releases p,
.black-theme anms-about .contributors p {
  background-color: #303030;
}
.black-theme anms-about .follow-releases p a,
.black-theme anms-about .contributors p a {
  color: #78909c;
}
.black-theme anms-about .follow-releases p a:hover,
.black-theme anms-about .contributors p a:hover {
  color: #455a64;
}
.black-theme anms-about .contributors img {
  border-color: #303030 !important;
}
.black-theme anms-big-input .big-input input {
  color: white;
  background-color: #424242;
}
.black-theme anms-big-input .big-input.has-focus {
  box-shadow: 0 0 15px 2px rgba(120, 144, 156, 0.4), 0 0 15px 2px rgba(120, 144, 156, 0.4), 0 0 15px 2px rgba(120, 144, 156, 0.4);
}
.black-theme .error {
  color: white;
  background-color: #f44336;
}
.black-theme .information {
  color: white;
  background-color: #78909c;
}
.black-theme .success {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.black-theme .warning {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.black-theme .todos-filter-menu-overlay .active {
  color: white;
  background-color: #78909c;
}
.black-theme .todos-filter-menu-overlay .active .fa-icon {
  color: white;
}
.black-theme .todos-filter-menu-overlay .active:hover {
  color: white;
  background-color: #78909c;
}
.black-theme .todos-notification-overlay .mat-simple-snackbar {
  align-items: center;
}
.black-theme .todos-notification-overlay .mat-simple-snackbar button {
  border-radius: 2px;
  background-color: #78909c;
  color: white;
}
.black-theme anms-stock-market mat-card .negative {
  color: #f44336;
}
.black-theme anms-stock-market .error-state fa-icon {
  color: #f44336;
}
.black-theme anms-parent > .container > .row > .col-md-6 > .example {
  border-color: #78909c;
}
.black-theme anms-parent > .container > .row > .col-md-6 > .example > h1 {
  color: #78909c;
}
.black-theme anms-child > div {
  border-color: #f44336;
}
.black-theme anms-child > div > h2 {
  color: #f44336;
}
.black-theme anms-examples .nav-responsive {
  border-bottom: 1px solid #616161;
}
.black-theme anms-examples .nav-responsive mat-select {
  line-height: 48px;
  margin: 0 20px;
  width: 100%;
}
.black-theme .nav-responsive-overlay .active {
  color: white;
  background-color: #78909c;
}

.nature-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.nature-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-option:hover:not(.mat-option-disabled), .nature-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5d4037;
}
.nature-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9575cd;
}
.nature-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e53935;
}
.nature-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.nature-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.nature-theme .mat-primary .mat-pseudo-checkbox-checked,
.nature-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #5d4037;
}
.nature-theme .mat-pseudo-checkbox-checked,
.nature-theme .mat-pseudo-checkbox-indeterminate,
.nature-theme .mat-accent .mat-pseudo-checkbox-checked,
.nature-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #9575cd;
}
.nature-theme .mat-warn .mat-pseudo-checkbox-checked,
.nature-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e53935;
}
.nature-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.nature-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.nature-theme .mat-app-background, .nature-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.nature-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.nature-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-badge {
  position: relative;
}
.nature-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.nature-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.nature-theme .ng-animate-disabled .mat-badge-content,
.nature-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.nature-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.nature-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.nature-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.nature-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.nature-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .nature-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.nature-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .nature-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.nature-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .nature-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.nature-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .nature-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.nature-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.nature-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.nature-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.nature-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .nature-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.nature-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .nature-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.nature-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .nature-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.nature-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .nature-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.nature-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.nature-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.nature-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.nature-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .nature-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.nature-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .nature-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.nature-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .nature-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.nature-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .nature-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.nature-theme .mat-badge-content {
  color: white;
  background: #5d4037;
}
.cdk-high-contrast-active .nature-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.nature-theme .mat-badge-accent .mat-badge-content {
  background: #9575cd;
  color: white;
}
.nature-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e53935;
}
.nature-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-button, .nature-theme .mat-icon-button, .nature-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.nature-theme .mat-button.mat-primary, .nature-theme .mat-icon-button.mat-primary, .nature-theme .mat-stroked-button.mat-primary {
  color: #5d4037;
}
.nature-theme .mat-button.mat-accent, .nature-theme .mat-icon-button.mat-accent, .nature-theme .mat-stroked-button.mat-accent {
  color: #9575cd;
}
.nature-theme .mat-button.mat-warn, .nature-theme .mat-icon-button.mat-warn, .nature-theme .mat-stroked-button.mat-warn {
  color: #e53935;
}
.nature-theme .mat-button.mat-primary.mat-button-disabled, .nature-theme .mat-button.mat-accent.mat-button-disabled, .nature-theme .mat-button.mat-warn.mat-button-disabled, .nature-theme .mat-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-icon-button.mat-primary.mat-button-disabled, .nature-theme .mat-icon-button.mat-accent.mat-button-disabled, .nature-theme .mat-icon-button.mat-warn.mat-button-disabled, .nature-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-stroked-button.mat-primary.mat-button-disabled, .nature-theme .mat-stroked-button.mat-accent.mat-button-disabled, .nature-theme .mat-stroked-button.mat-warn.mat-button-disabled, .nature-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-button.mat-primary .mat-button-focus-overlay, .nature-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .nature-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #5d4037;
}
.nature-theme .mat-button.mat-accent .mat-button-focus-overlay, .nature-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .nature-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #9575cd;
}
.nature-theme .mat-button.mat-warn .mat-button-focus-overlay, .nature-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .nature-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e53935;
}
.nature-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .nature-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .nature-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.nature-theme .mat-button .mat-ripple-element, .nature-theme .mat-icon-button .mat-ripple-element, .nature-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.nature-theme .mat-button-focus-overlay {
  background: black;
}
.nature-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-flat-button, .nature-theme .mat-raised-button, .nature-theme .mat-fab, .nature-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.nature-theme .mat-flat-button.mat-primary, .nature-theme .mat-raised-button.mat-primary, .nature-theme .mat-fab.mat-primary, .nature-theme .mat-mini-fab.mat-primary {
  color: white;
}
.nature-theme .mat-flat-button.mat-accent, .nature-theme .mat-raised-button.mat-accent, .nature-theme .mat-fab.mat-accent, .nature-theme .mat-mini-fab.mat-accent {
  color: white;
}
.nature-theme .mat-flat-button.mat-warn, .nature-theme .mat-raised-button.mat-warn, .nature-theme .mat-fab.mat-warn, .nature-theme .mat-mini-fab.mat-warn {
  color: white;
}
.nature-theme .mat-flat-button.mat-primary.mat-button-disabled, .nature-theme .mat-flat-button.mat-accent.mat-button-disabled, .nature-theme .mat-flat-button.mat-warn.mat-button-disabled, .nature-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-raised-button.mat-primary.mat-button-disabled, .nature-theme .mat-raised-button.mat-accent.mat-button-disabled, .nature-theme .mat-raised-button.mat-warn.mat-button-disabled, .nature-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-fab.mat-primary.mat-button-disabled, .nature-theme .mat-fab.mat-accent.mat-button-disabled, .nature-theme .mat-fab.mat-warn.mat-button-disabled, .nature-theme .mat-fab.mat-button-disabled.mat-button-disabled, .nature-theme .mat-mini-fab.mat-primary.mat-button-disabled, .nature-theme .mat-mini-fab.mat-accent.mat-button-disabled, .nature-theme .mat-mini-fab.mat-warn.mat-button-disabled, .nature-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-flat-button.mat-primary, .nature-theme .mat-raised-button.mat-primary, .nature-theme .mat-fab.mat-primary, .nature-theme .mat-mini-fab.mat-primary {
  background-color: #5d4037;
}
.nature-theme .mat-flat-button.mat-accent, .nature-theme .mat-raised-button.mat-accent, .nature-theme .mat-fab.mat-accent, .nature-theme .mat-mini-fab.mat-accent {
  background-color: #9575cd;
}
.nature-theme .mat-flat-button.mat-warn, .nature-theme .mat-raised-button.mat-warn, .nature-theme .mat-fab.mat-warn, .nature-theme .mat-mini-fab.mat-warn {
  background-color: #e53935;
}
.nature-theme .mat-flat-button.mat-primary.mat-button-disabled, .nature-theme .mat-flat-button.mat-accent.mat-button-disabled, .nature-theme .mat-flat-button.mat-warn.mat-button-disabled, .nature-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-raised-button.mat-primary.mat-button-disabled, .nature-theme .mat-raised-button.mat-accent.mat-button-disabled, .nature-theme .mat-raised-button.mat-warn.mat-button-disabled, .nature-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .nature-theme .mat-fab.mat-primary.mat-button-disabled, .nature-theme .mat-fab.mat-accent.mat-button-disabled, .nature-theme .mat-fab.mat-warn.mat-button-disabled, .nature-theme .mat-fab.mat-button-disabled.mat-button-disabled, .nature-theme .mat-mini-fab.mat-primary.mat-button-disabled, .nature-theme .mat-mini-fab.mat-accent.mat-button-disabled, .nature-theme .mat-mini-fab.mat-warn.mat-button-disabled, .nature-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-flat-button.mat-primary .mat-ripple-element, .nature-theme .mat-raised-button.mat-primary .mat-ripple-element, .nature-theme .mat-fab.mat-primary .mat-ripple-element, .nature-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-flat-button.mat-accent .mat-ripple-element, .nature-theme .mat-raised-button.mat-accent .mat-ripple-element, .nature-theme .mat-fab.mat-accent .mat-ripple-element, .nature-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-flat-button.mat-warn .mat-ripple-element, .nature-theme .mat-raised-button.mat-warn .mat-ripple-element, .nature-theme .mat-fab.mat-warn .mat-ripple-element, .nature-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-stroked-button:not([class*=mat-elevation-z]), .nature-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-fab:not([class*=mat-elevation-z]), .nature-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .nature-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .nature-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-button-toggle-standalone,
.nature-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.nature-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.nature-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.nature-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.nature-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.nature-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.nature-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.nature-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.nature-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.nature-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.nature-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.nature-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .nature-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #5d4037;
}
.nature-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .nature-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #9575cd;
}
.nature-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .nature-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e53935;
}
.nature-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .nature-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.nature-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.nature-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.nature-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.nature-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #5d4037;
}
.nature-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.nature-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #9575cd;
}
.nature-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.nature-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e53935;
}
.nature-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.nature-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.nature-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #5d4037;
  color: white;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e53935;
  color: white;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #9575cd;
  color: white;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.nature-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.nature-theme .mat-table {
  background: white;
}
.nature-theme .mat-table thead, .nature-theme .mat-table tbody, .nature-theme .mat-table tfoot,
.nature-theme mat-header-row, .nature-theme mat-row, .nature-theme mat-footer-row,
.nature-theme [mat-header-row], .nature-theme [mat-row], .nature-theme [mat-footer-row],
.nature-theme .mat-table-sticky {
  background: inherit;
}
.nature-theme mat-row, .nature-theme mat-header-row, .nature-theme mat-footer-row,
.nature-theme th.mat-header-cell, .nature-theme td.mat-cell, .nature-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-cell, .nature-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-datepicker-toggle,
.nature-theme .mat-datepicker-content .mat-calendar-next-button,
.nature-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-calendar-table-header,
.nature-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-calendar-body-cell-content,
.nature-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.nature-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.nature-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.nature-theme .mat-calendar-body-in-range::before {
  background: rgba(93, 64, 55, 0.2);
}
.nature-theme .mat-calendar-body-comparison-identical,
.nature-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.nature-theme .mat-calendar-body-comparison-bridge-start::before,
.nature-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(93, 64, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-calendar-body-comparison-bridge-end::before,
.nature-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(93, 64, 55, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.nature-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.nature-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.nature-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.nature-theme .mat-calendar-body-selected {
  background-color: #5d4037;
  color: white;
}
.nature-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(93, 64, 55, 0.4);
}
.nature-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.nature-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(93, 64, 55, 0.3);
}
.nature-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(149, 117, 205, 0.2);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.nature-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(149, 117, 205, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.nature-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(149, 117, 205, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #9575cd;
  color: white;
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(149, 117, 205, 0.4);
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.nature-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(149, 117, 205, 0.3);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 57, 53, 0.2);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.nature-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.nature-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e53935;
  color: white;
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 57, 53, 0.4);
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.nature-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.nature-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 57, 53, 0.3);
}
.nature-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-datepicker-toggle-active {
  color: #5d4037;
}
.nature-theme .mat-datepicker-toggle-active.mat-accent {
  color: #9575cd;
}
.nature-theme .mat-datepicker-toggle-active.mat-warn {
  color: #e53935;
}
.nature-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .nature-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .nature-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .nature-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.nature-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-expansion-panel-header-description,
.nature-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.nature-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.nature-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.nature-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #5d4037;
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #9575cd;
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e53935;
}
.nature-theme .mat-focused .mat-form-field-required-marker {
  color: #9575cd;
}
.nature-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #5d4037;
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #9575cd;
}
.nature-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e53935;
}
.nature-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #5d4037;
}
.nature-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #9575cd;
}
.nature-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e53935;
}
.nature-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e53935;
}
.nature-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.nature-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e53935;
}
.nature-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.nature-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e53935;
}
.nature-theme .mat-error {
  color: #e53935;
}
.nature-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.nature-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.nature-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.nature-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.nature-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #5d4037;
}
.nature-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #9575cd;
}
.nature-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e53935;
}
.nature-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e53935;
}
.nature-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.nature-theme .mat-icon.mat-primary {
  color: #5d4037;
}
.nature-theme .mat-icon.mat-accent {
  color: #9575cd;
}
.nature-theme .mat-icon.mat-warn {
  color: #e53935;
}
.nature-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-input-element:disabled,
.nature-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-input-element {
  caret-color: #5d4037;
}
.nature-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #9575cd;
}
.nature-theme .mat-form-field.mat-warn .mat-input-element,
.nature-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #e53935;
}
.nature-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e53935;
}
.nature-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-list-item-disabled {
  background-color: #eeeeee;
}
.nature-theme .mat-list-option:hover, .nature-theme .mat-list-option:focus,
.nature-theme .mat-nav-list .mat-list-item:hover,
.nature-theme .mat-nav-list .mat-list-item:focus,
.nature-theme .mat-action-list .mat-list-item:hover,
.nature-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-list-single-selected-option, .nature-theme .mat-list-single-selected-option:hover, .nature-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-menu-panel {
  background: white;
}
.nature-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-menu-item[disabled],
.nature-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.nature-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-menu-item .mat-icon-no-color,
.nature-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-menu-item:hover:not([disabled]),
.nature-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.nature-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.nature-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-paginator {
  background: white;
}
.nature-theme .mat-paginator,
.nature-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-paginator-decrement,
.nature-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-paginator-first,
.nature-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.nature-theme .mat-icon-button[disabled] .mat-paginator-increment,
.nature-theme .mat-icon-button[disabled] .mat-paginator-first,
.nature-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-progress-bar-background {
  fill: #d3ccc9;
}
.nature-theme .mat-progress-bar-buffer {
  background-color: #d3ccc9;
}
.nature-theme .mat-progress-bar-fill::after {
  background-color: #5d4037;
}
.nature-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #e1d9ef;
}
.nature-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #e1d9ef;
}
.nature-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #9575cd;
}
.nature-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cac9;
}
.nature-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cac9;
}
.nature-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e53935;
}
.nature-theme .mat-progress-spinner circle, .nature-theme .mat-spinner circle {
  stroke: #5d4037;
}
.nature-theme .mat-progress-spinner.mat-accent circle, .nature-theme .mat-spinner.mat-accent circle {
  stroke: #9575cd;
}
.nature-theme .mat-progress-spinner.mat-warn circle, .nature-theme .mat-spinner.mat-warn circle {
  stroke: #e53935;
}
.nature-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5d4037;
}
.nature-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.nature-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .nature-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .nature-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #5d4037;
}
.nature-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9575cd;
}
.nature-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.nature-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .nature-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .nature-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #9575cd;
}
.nature-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e53935;
}
.nature-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.nature-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .nature-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .nature-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e53935;
}
.nature-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.nature-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.nature-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.nature-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.nature-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-select-panel {
  background: white;
}
.nature-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #5d4037;
}
.nature-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #9575cd;
}
.nature-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e53935;
}
.nature-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e53935;
}
.nature-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.nature-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.nature-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.nature-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.nature-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9575cd;
}
.nature-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(149, 117, 205, 0.54);
}
.nature-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #9575cd;
}
.nature-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #5d4037;
}
.nature-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(93, 64, 55, 0.54);
}
.nature-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #5d4037;
}
.nature-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e53935;
}
.nature-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 57, 53, 0.54);
}
.nature-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e53935;
}
.nature-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.nature-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.nature-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-primary .mat-slider-track-fill,
.nature-theme .mat-primary .mat-slider-thumb,
.nature-theme .mat-primary .mat-slider-thumb-label {
  background-color: #5d4037;
}
.nature-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.nature-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(93, 64, 55, 0.2);
}
.nature-theme .mat-accent .mat-slider-track-fill,
.nature-theme .mat-accent .mat-slider-thumb,
.nature-theme .mat-accent .mat-slider-thumb-label {
  background-color: #9575cd;
}
.nature-theme .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.nature-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(149, 117, 205, 0.2);
}
.nature-theme .mat-warn .mat-slider-track-fill,
.nature-theme .mat-warn .mat-slider-thumb,
.nature-theme .mat-warn .mat-slider-thumb-label {
  background-color: #e53935;
}
.nature-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.nature-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 57, 53, 0.2);
}
.nature-theme .mat-slider:hover .mat-slider-track-background,
.nature-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-slider-disabled .mat-slider-track-background,
.nature-theme .mat-slider-disabled .mat-slider-track-fill,
.nature-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.nature-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.nature-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.nature-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .nature-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .nature-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.nature-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.nature-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.nature-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.nature-theme .mat-step-header.cdk-keyboard-focused, .nature-theme .mat-step-header.cdk-program-focused, .nature-theme .mat-step-header:hover:not([aria-disabled]), .nature-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.nature-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .nature-theme .mat-step-header:hover {
    background: none;
  }
}
.nature-theme .mat-step-header .mat-step-label,
.nature-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.nature-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.nature-theme .mat-step-header .mat-step-icon-selected,
.nature-theme .mat-step-header .mat-step-icon-state-done,
.nature-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #5d4037;
  color: white;
}
.nature-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.nature-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.nature-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.nature-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #9575cd;
  color: white;
}
.nature-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.nature-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.nature-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.nature-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e53935;
  color: white;
}
.nature-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e53935;
}
.nature-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e53935;
}
.nature-theme .mat-stepper-horizontal, .nature-theme .mat-stepper-vertical {
  background-color: white;
}
.nature-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-horizontal-stepper-header::before,
.nature-theme .mat-horizontal-stepper-header::after,
.nature-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-sort-header-arrow {
  color: #757575;
}
.nature-theme .mat-tab-nav-bar,
.nature-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.nature-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.nature-theme .mat-tab-label, .nature-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.nature-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.nature-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.nature-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(161, 136, 127, 0.3);
}
.nature-theme .mat-tab-group.mat-primary .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #5d4037;
}
.nature-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.nature-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(209, 196, 233, 0.3);
}
.nature-theme .mat-tab-group.mat-accent .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #9575cd;
}
.nature-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.nature-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.nature-theme .mat-tab-group.mat-warn .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e53935;
}
.nature-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .nature-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.nature-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(161, 136, 127, 0.3);
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .nature-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #5d4037;
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.nature-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(209, 196, 233, 0.3);
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .nature-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #9575cd;
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.nature-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .nature-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.nature-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .nature-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e53935;
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.nature-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.nature-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-toolbar.mat-primary {
  background: #5d4037;
  color: white;
}
.nature-theme .mat-toolbar.mat-accent {
  background: #9575cd;
  color: white;
}
.nature-theme .mat-toolbar.mat-warn {
  background: #e53935;
  color: white;
}
.nature-theme .mat-toolbar .mat-form-field-underline,
.nature-theme .mat-toolbar .mat-form-field-ripple,
.nature-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.nature-theme .mat-toolbar .mat-form-field-label,
.nature-theme .mat-toolbar .mat-focused .mat-form-field-label,
.nature-theme .mat-toolbar .mat-select-value,
.nature-theme .mat-toolbar .mat-select-arrow,
.nature-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.nature-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.nature-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.nature-theme .mat-tree {
  background: white;
}
.nature-theme .mat-tree-node,
.nature-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.nature-theme .mat-simple-snackbar-action {
  color: #9575cd;
}
.nature-theme a {
  color: rgba(0, 0, 0, 0.87);
}
.nature-theme a:hover {
  color: #9575cd;
}
.nature-theme code {
  color: rgba(0, 0, 0, 0.87);
  background-color: #d1c4e9;
}
.nature-theme p a {
  color: #9575cd;
}
.nature-theme p a:hover {
  color: #512da8;
}
.nature-theme mat-sidenav-container mat-toolbar button.active {
  color: white;
  background-color: #9575cd;
}
.nature-theme mat-sidenav-container mat-toolbar .link {
  color: white;
}
.nature-theme mat-sidenav-container mat-sidenav .mat-list-item.active {
  color: white;
  background-color: #9575cd !important;
}
.nature-theme mat-sidenav-container mat-sidenav .mat-list-item.active:hover {
  background-color: #512da8 !important;
}
.nature-theme mat-sidenav-container .footer {
  color: #a1887f;
  background-color: #3e2723;
}
.nature-theme mat-sidenav-container .footer .links a,
.nature-theme mat-sidenav-container .footer .signature a {
  color: #a1887f;
}
.nature-theme mat-sidenav-container .footer .links a:hover,
.nature-theme mat-sidenav-container .footer .signature a:hover {
  color: #9575cd;
}
.nature-theme .error-notification-overlay {
  color: white;
  background-color: #e53935;
}
.nature-theme .info-notification-overlay {
  color: white;
  background-color: #9575cd;
}
.nature-theme .success-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.nature-theme .warning-notification-overlay {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.nature-theme anms-about .background {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}
.nature-theme anms-about .background .gradient::before {
  background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}
.nature-theme anms-about .follow-releases p,
.nature-theme anms-about .contributors p {
  background-color: #fafafa;
}
.nature-theme anms-about .follow-releases p a,
.nature-theme anms-about .contributors p a {
  color: #9575cd;
}
.nature-theme anms-about .follow-releases p a:hover,
.nature-theme anms-about .contributors p a:hover {
  color: #512da8;
}
.nature-theme anms-about .contributors img {
  border-color: #fafafa !important;
}
.nature-theme anms-big-input .big-input input {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.nature-theme anms-big-input .big-input.has-focus {
  box-shadow: 0 0 15px 2px rgba(149, 117, 205, 0.4), 0 0 15px 2px rgba(149, 117, 205, 0.4), 0 0 15px 2px rgba(149, 117, 205, 0.4);
}
.nature-theme .error {
  color: white;
  background-color: #e53935;
}
.nature-theme .information {
  color: white;
  background-color: #9575cd;
}
.nature-theme .success {
  color: rgba(0, 0, 0, 0.87);
  background-color: #66bb6a;
}
.nature-theme .warning {
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffca28;
}
.nature-theme .todos-filter-menu-overlay .active {
  color: white;
  background-color: #9575cd;
}
.nature-theme .todos-filter-menu-overlay .active .fa-icon {
  color: white;
}
.nature-theme .todos-filter-menu-overlay .active:hover {
  color: white;
  background-color: #9575cd;
}
.nature-theme .todos-notification-overlay .mat-simple-snackbar {
  align-items: center;
}
.nature-theme .todos-notification-overlay .mat-simple-snackbar button {
  border-radius: 2px;
  background-color: #9575cd;
  color: white;
}
.nature-theme anms-stock-market mat-card .negative {
  color: #e53935;
}
.nature-theme anms-stock-market .error-state fa-icon {
  color: #e53935;
}
.nature-theme anms-parent > .container > .row > .col-md-6 > .example {
  border-color: #9575cd;
}
.nature-theme anms-parent > .container > .row > .col-md-6 > .example > h1 {
  color: #9575cd;
}
.nature-theme anms-child > div {
  border-color: #e53935;
}
.nature-theme anms-child > div > h2 {
  color: #e53935;
}
.nature-theme anms-examples .nav-responsive {
  border-bottom: 1px solid #5d4037;
}
.nature-theme anms-examples .nav-responsive mat-select {
  line-height: 48px;
  margin: 0 20px;
  width: 100%;
}
.nature-theme .nav-responsive-overlay .active {
  color: white;
  background-color: #9575cd;
}